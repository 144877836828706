import React, { Fragment } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import styled from 'styled-components';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import { GlobalStyle, ContentWrapper } from '../containers/sassModern.style';

import BannerSectionSmall from '../containers/BannerSmall';
import Navbar from '../containers/Navbar';
import Footer from '../containers/Footer';
import SEO from '../components/seo';
import Box from '../../../common/src/components/Box';
import Container from '../../../common/src/components/UI/Container';
import { useTranslation } from 'react-i18next';

const TermsAndConditionContent = styled.div`
  font-family: 'Roboto', sans-serif;
  line-height: 2;
  color: #343d48cc;

  a {
    color: #343d48cc;
    text-decoration: underline;
  }

  ul {
    margin-left: 40px;
  }

  li {
    list-style-type: disc;
  }
`;

export default ({ pageContext }) => {
  const { t } = useTranslation();
  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <SEO
          title={t('Terms and conditions')}
          lang={pageContext.lang}
          meta={[
            {
              property: `robots`,
              content: 'noindex,nofollow',
            },
          ]}
        />

        <ResetCSS />
        <GlobalStyle />

        <ContentWrapper>
          <Sticky top={0} innerZ={9999} activeClass="sticky-nav-active">
            <DrawerProvider>
              <Navbar pageContext={pageContext} />
            </DrawerProvider>
          </Sticky>
          <BannerSectionSmall
            titleText={t('Terms and conditions')}
            descriptionText={t('The general terms and conditions of Psono.com')}
          />
          <Box as="section" id="legal_notice_section">
            <Container>
              <TermsAndConditionContent>
                <p>
                  <b>1. Preamble</b>
                </p>
                <p>
                  These terms and conditions including all linked documents
                  ("Agreement") are entered into by esaqa GmbH, Tiergartenstr.
                  13, 91247 Vorra, Germany ("esaqa") and the entity or person
                  agreeing to these terms and conditions ("Customer") and govern
                  Customer's access to and use of Psono and all online Services,
                  licenses and its corresponding software (all together
                  "Services") that can be obtained on psono.com.
                </p>
                <br />
                <p>
                  <b>2. General</b>
                </p>
                <p>
                  2.1 esaqa may make Services available to Customer, including
                  third-party services.
                </p>
                <p> 2.2 esaqa may modify Services from time to time. </p>
                <p>
                  2.3 esaqa may modify this agreement from time to time
                  including pricing (and any linked documents) with an
                  appropriate notice of at least 4 weeks.
                </p>
                <p>
                  2.4 Unless otherwise agreed Customer must have an account to
                  use Services and sole responsible for the provided
                  information, security of is authentication credentials and use
                  of its account.
                </p>
                <p>
                  2.3 Customer may only use the Services if agreeing to these
                  terms and condtions.
                </p>
                <br />
                <p>
                  <b>3. Termination</b>
                </p>
                <p>
                  3.1 Either party may terminate this Agreement if (a) the other
                  party is in material breach of the Agreement and fails to cure
                  that breach within 30 days after receipt of written notice or
                  (b) the other party ceases its business operations or becomes
                  subject to insolvency proceedings and the proceedings are not
                  dismissed within 90 days.
                </p>
                <p>
                  3.2 esaqa reserves the right to terminate the provision of the
                  Services to a Project upon 30 days' advance notice if, for a
                  period of 60 days (a) Customer has not accessed the psono.com
                  or the Project has had no network activity and (b) such
                  Project has not incurred any Fees for such Services.
                </p>
                <p>
                  3.3 Customer may stop using the Services at any time. Customer
                  may terminate this Agreement for its convenience at any time
                  on prior written notice and, upon termination, must cease use
                  of the applicable Services. esaqa may terminate this Agreement
                  for its convenience at any time with 30 days' prior written
                  notice to Customer.
                </p>

                <br />
                <p>
                  <b>4. Subcontracting</b>
                </p>
                <p>
                  esaqa may subcontract obligations under the Agreement but will
                  remain liable to Customer for any subcontracted obligations.
                </p>
                <br />
                <p>
                  <b>5. Customer Obligations</b>
                </p>
                <p>
                  5.1. Customer will (a) ensure that Customer and its end users'
                  use of the Services complies with the Agreement, (b) use
                  commercially reasonable efforts to prevent and terminate any
                  unauthorized use of, or access to, the Services, and (c)
                  promptly notify esaqa of any unauthorized use of, or access
                  to, the Services, account, or Customer's authentication
                  credentials of which Customer becomes aware. esaqa reserves
                  the right to investigate any potential violation of the
                  Acceptable Use Policy (AUP
                  https://psono.com/acceptable-use-policy/) by Customer, which
                  may include reviewing Customer applications, customer data, or
                  projects.
                </p>
                <p>
                  4.2. Customer is responsible for any consents and notices
                  required to permit (a) Customer and end user's use and receipt
                  of Services and (b) esaqa's accessing, storing, and processing
                  of data provided by Customer and end user (including customer
                  data, if applicable) under the Agreement.
                </p>
                <p>
                  5.3. Customer will not, and will not allow end users to, (a)
                  copy, modify, or create a derivative work of the Services; (b)
                  reverse engineer, decompile, translate, disassemble, or
                  otherwise attempt to extract any or all of the source code of
                  the Services (except to the extent such restriction is
                  expressly prohibited by applicable law); (c) sell, resell,
                  sublicense, transfer, or distribute any or all of the
                  Services; or (d) access or use the Services (i) for High Risk
                  Activities; (ii) in violation of the AUP; (iii) in a manner
                  intended to avoid incurring Fees (including creating multiple
                  customer applications, accounts, or projects to simulate or
                  act as a single customer application, account, or project
                  (respectively)) or to circumvent service-specific usage limits
                  or quotas; (iv) to engage in cryptocurrency mining without
                  esaqa's prior written approval; (v) to operate or enable any
                  telecommunications service or in connection with any customer
                  application that allows end users to place calls or to receive
                  calls from any public switched telephone network, unless
                  otherwise described in the service specific terms (described
                  in Section 14); (vi) for materials or activities that are
                  subject to the International Traffic in Arms Regulations
                  (ITAR); (vii) in a manner that breaches, or causes the breach
                  of, Export Control Laws; or (viii) to transmit, store, or
                  process health information subject to government regulations.
                </p>
                <br />
                <p>
                  <b>6. Intellectual Property</b>
                </p>
                <p>
                  Except as expressly stated in this Agreement, this Agreement
                  does not grant Customer any rights, implied or otherwise, to
                  the other's content or any of the other's intellectual
                  property. esaqa owns all Intellectual Property Rights in the
                  Services and software.
                </p>
                <br />
                <p>
                  <b>7. Technical support</b>
                </p>
                <p>
                  esaqa offers technical support under separate support
                  contracts. The provision of technical support without support
                  contracts is at the sole discretion of esaqa and is not
                  associated with any guarantee or warranty. It is Customer
                  responsibility to back up all of existing data, software and
                  programs before receiving technical support from esaqa. esaqa
                  reserves the right, in its sole discretion, to refuse, suspend
                  or terminate any technical support.
                </p>
                <br />
                <p>
                  <b>8. Limitation of liability</b>
                </p>
                <p>
                  Neither we nor our related parties are liable to you or any
                  third party for any loss of profits, loss of use, loss of
                  revenue, loss of goodwill, any interruption of business, loss
                  of or damage to business or reputation, loss of opportunity,
                  loss of advantage, loss of use of any software or data, loss
                  of use of any mobile phone or other equipment, loss of use of
                  the system on which the site, esaqa software or esaqa services
                  are used, or for any indirect, special, incidental, exemplary,
                  punitive or consequential damages of any kind arising out of
                  or in connection with this agreement, the site, the esaqa
                  software, the esaqa services or third party content,
                  regardless of the form of action, whether in contract, tort,
                  strict liability or otherwise, even if we have been advised or
                  are otherwise aware of the possibility of such damages.
                </p>
                <p>
                  In no event shall our and our related party’s total cumulative
                  liability for any loss of profits, loss of use, loss of
                  revenue, loss of goodwill, any interruption of business, loss
                  of or damage to business or reputation, loss of opportunity,
                  loss of advantage, loss of use of any software or data, loss
                  of use of any mobile phone or other equipment, loss of use of
                  the system on which the site, esaqa software or esaqa services
                  are used, or for any direct, indirect, special, incidental,
                  exemplary, punitive or consequential damages of any kind
                  arising out of or in connection with this agreement, the site,
                  the esaqa software, the esaqa services or third party content,
                  regardless of the form of action, whether in contract, tort,
                  strict liability or otherwise, even if we or our related
                  parties have been advised or are otherwise aware of the
                  possibility of such damages, exceed the amount paid by you for
                  your use of the esaqa services and the esaqa software in the
                  six (6) months prior to the accrual of the first claim.
                  multiple claims will not expand this limitation. this section
                  will be given full effect even if any remedy specified in this
                  agreement is deemed to have failed of its essential purpose.
                </p>
                <br />
                <p>
                  <b>9. Legal notice</b>
                </p>
                <p>
                  Communications made through e-mail and other non-physical
                  messaging systems, will not constitute legal notice to esaqa
                  or any of its officers, employees, agents or representatives
                  in any situation where written paper notice to esaqa is
                  required by contract or any law or regulation.
                </p>
                <br />
                <p>
                  <b>10. No agency</b>
                </p>
                <p>
                  This agreement does not create an agency, partnership, or
                  joint venture.
                </p>
                <br />
                <p>
                  <b>11. German export jurisdiction</b>
                </p>
                <p>
                  You must comply with all applicable laws, including the German
                  Export Regulations, the International Traffic in Arms
                  Regulations, and end-user, end-use and destination
                  restrictions issued by Germany and other governments.
                </p>
                <br />
                <p>
                  <b>12. Force majeure</b>
                </p>
                <p>
                  Neither party will be liable for any failure in performance
                  due to causes beyond that party’s reasonable control (such as
                  fire, explosion, power blackout, earthquake, flood, severe
                  storms, strike, embargo, labor disputes, acts of civil or
                  military authority, war, terrorism (including cyber
                  terrorism), acts of God, acts or omissions of Internet traffic
                  carriers, actions or omissions of regulatory or governmental
                  bodies (including the passage of laws or regulations or other
                  acts of government that impact the delivery of Services)).
                  This Section will not, however, apply to your payment
                  obligations under this agreement.
                </p>
                <br />
                <p>
                  <b>13. No third-party beneficiaries</b>
                </p>
                <p>There are no third-party beneficiaries to this agreement.</p>
                <br />
                <p>
                  <b>14. Change of control</b>
                </p>
                <p>
                  If a party experiences a change of control (for example,
                  through a stock purchase or sale, merger, or other form of
                  corporate transaction), that party will give written notice to
                  the other party within 30 days after the change of control.
                </p>
                <br />
                <p>
                  <b>15. Authority</b>
                </p>
                <p>
                  If you are an individual accepting these terms on behalf of an
                  entity, you represent that you have the legal authority to
                  enter into this agreement on that entity’s behalf. If you
                  specify an entity, or you use an email address provided by an
                  entity you are affiliated with (such as an employer) in
                  connection with a service purchase or renewal, that entity
                  will be treated as the owner of the service for purposes of
                  this agreement.
                </p>
                <br />
                <p>
                  <b>16. Waiver</b>
                </p>
                <p>
                  Failure to enforce any provision of this agreement will not
                  constitute a waiver.
                </p>
                <br />
                <p>
                  <b>17. Jurisdiction</b>
                </p>
                <p> The place of jurisdiction is Nuremberg, Germany.</p>
                <br />
                <p>
                  <b>18. Severability clause</b>
                </p>
                <p>
                  Should individual terms of this Agreement be entirely or
                  partly ineffective or lose their legal effectiveness due to
                  later circumstances, the validity of the other provisions is
                  not affected. The invalid provision shall be replaced by an
                  appropriate provision which comes as close as possible to what
                  the Parties to the agreement would have wanted, had they known
                  about the invalid provision.
                </p>
                <br />
                <p>
                  esaqa may update its privacy statement from time to time and
                  we encourage everyone to check regularly. It has last been
                  updated March 27th, 2021.
                </p>
              </TermsAndConditionContent>
            </Container>
          </Box>

          <Footer pageContext={pageContext} />
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};
